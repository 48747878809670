<template>
  <div class="Category-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar
          v-if="Category"
          :title="`Profit & Lost`"
          :start="start"
          :end="end"
        />

        <table class="center">
          <tbody class="report-container">
            <v-card-text>
              <v-expansion-panels
                v-model="panel"
                flat
                hover
                accordion
                focusable
                multiple
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="headline">
                    <span>INCOME</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <CategoryByProduct
                      @fatchData="setIncome"
                      :start="$route.query.start"
                      :end="$route.query.end"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header class="headline">
                    <span>EXPENSE</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      dense
                      :headers="expenseHeaders"
                      :itemsPerPage="Expense.length"
                      :expanded.sync="expenseExpanded"
                      show-expand
                      hide-default-footer
                      :items="Expense"
                    >
                      <template v-slot:item="{ item, isExpanded, expand }">
                        <tr v-if="item.totalExpenses > 0">
                          <td class="text-left">
                            <v-btn
                              class="no-print"
                              @click="expand(true)"
                              icon
                              v-if="!isExpanded"
                              ><v-icon>mdi-chevron-down</v-icon></v-btn
                            >
                            <v-btn
                              class="no-print"
                              @click="expand(false)"
                              icon
                              v-if="isExpanded"
                              ><v-icon>mdi-chevron-up</v-icon></v-btn
                            >
                          </td>
                          <td class="text-left text-capitalize">
                            {{ item.name }}
                          </td>
                          <td class="text-right">
                            {{ item.totalExpenses | currency }}
                          </td>
                        </tr>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-0">
                          <v-card color="#F3F8FC" flat>
                            <v-card-text class="title">
                              <ExpenseList :PayeeId="item.id"></ExpenseList>
                            </v-card-text>
                          </v-card>
                        </td>
                      </template>
                      <template v-slot:body.append>
                        <tr
                          :style="{
                            'background-color': $style.listTotal.filter,
                          }"
                        >
                          <td></td>
                          <td class="font-weight-bold">Total</td>
                          <td class="text-right font-weight-bold">
                            {{ totalExpense | currency }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>

            <v-card-text>
              <v-card color="#E5E5E5" class="px-5 py-2" flat>
                <v-row
                  class="headline font-weight-regular"
                  :style="{ color: profitColor }"
                >
                  <v-col>PROFIT</v-col>
                  <v-spacer></v-spacer>
                  <v-col class="text-right">
                    {{ totalProfit | currency }}</v-col
                  >
                </v-row>
              </v-card>
            </v-card-text>
          </tbody>
        </table>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
import payeeService from "@/modules/Expense/Payee/service";
const CategoryByProduct = () =>
  import("@/modules/ReportView/GlobalReport/ProfitByCategory.vue");
const ExpenseList = () =>
  import("@/modules/ReportView/components/subComponents/Expense");

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      start: null,
      categoryProfit: 0,
      end: null,
      panel: [0, 1],
      isLoading: true,
      Category: [],
      Expense: [],
      expanded: [],
      expenseExpanded: [],
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: "NAME",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "SALE",
          align: "right",
          value: "totalPrice",
          sortable: true,
        },
        {
          text: "COST",
          align: "right",
          value: "totalCost",
          sortable: true,
        },
        {
          text: "PROFIT",
          align: "right",
          value: "totalProfit",
          sortable: true,
        },
      ],
      expenseHeaders: [
        { text: "", value: "data-table-expand" },
        {
          text: "NAME",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "AMOUNT",
          align: "right",
          value: "totalExpenses",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    totalProfit() {
      return this.categoryProfit - this.totalExpense;
    },
    profitColor() {
      let color = "green";
      if (this.totalProfit > 0) {
        color = "green";
      } else {
        color = "red";
      }
      return color;
    },
    // categoryProfit() {
    //   let total = 0;
    //   this.Category.map((row) => {
    //     total += row.totalPrice - row.totalCost;
    //   });
    //   return total;
    // },
    totalExpense() {
      let total = 0;
      this.Expense.map((row) => {
        total += row.totalExpenses;
      });
      return total;
    },
  },
  async created() {
    if (!this.currentBusiness) this.initUser();
    this.start = this.$route.query.start;
    this.end = this.$route.query.end;
    await this.getPayeeExpenses();
  },
  components: { CategoryByProduct, ReportViewBar, ExpenseList },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    setIncome(val) {
      this.categoryProfit = val;
    },
    color(item) {
      let value = "black";
      let profit = item.totalPrice - item.totalCost;
      if (profit < 0) {
        value = "red";
      }
      return value;
    },
    openProductReport(category) {
      // console.log("category", category);
      let routeData = this.$router.resolve({
        path: `/reportView/profitAndLose/byProduct?start=${this.start}&end=${this.end}&CategoryId=${category.id}`,
      });
      window.open(routeData.href, "_blank");

      // this.$router.push({path: '/reportView/sale/byProduct', query: { start: this.start, end: this.end }})
    },
    getPayeeExpenses() {
      return payeeService
        .getAllPayeeExpenseTotal({ start: this.start, end: this.end })
        .then((response) => {
          // console.log("expenses response", response);
          this.$Progress.finish();
          this.Expense = response.data;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Category-view"},[(_vm.isLoading)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-progress-linear',{attrs:{"indeterminate":true}})],1)],1):_c('div',[_c('v-card',{attrs:{"flat":""}},[(_vm.Category)?_c('ReportViewBar',{attrs:{"title":"Profit & Lost","start":_vm.start,"end":_vm.end}}):_vm._e(),_c('table',{staticClass:"center"},[_c('tbody',{staticClass:"report-container"},[_c('v-card-text',[_c('v-expansion-panels',{attrs:{"flat":"","hover":"","accordion":"","focusable":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"headline"},[_c('span',[_vm._v("INCOME")])]),_c('v-expansion-panel-content',[_c('CategoryByProduct',{attrs:{"start":_vm.$route.query.start,"end":_vm.$route.query.end},on:{"fatchData":_vm.setIncome}})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"headline"},[_c('span',[_vm._v("EXPENSE")])]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.expenseHeaders,"itemsPerPage":_vm.Expense.length,"expanded":_vm.expenseExpanded,"show-expand":"","hide-default-footer":"","items":_vm.Expense},on:{"update:expanded":function($event){_vm.expenseExpanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.totalExpenses > 0)?_c('tr',[_c('td',{staticClass:"text-left"},[(!isExpanded)?_c('v-btn',{staticClass:"no-print",attrs:{"icon":""},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),(isExpanded)?_c('v-btn',{staticClass:"no-print",attrs:{"icon":""},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e()],1),_c('td',{staticClass:"text-left text-capitalize"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.totalExpenses))+" ")])]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"color":"#F3F8FC","flat":""}},[_c('v-card-text',{staticClass:"title"},[_c('ExpenseList',{attrs:{"PayeeId":item.id}})],1)],1)],1)]}},{key:"body.append",fn:function(){return [_c('tr',{style:({
                          'background-color': _vm.$style.listTotal.filter,
                        })},[_c('td'),_c('td',{staticClass:"font-weight-bold"},[_vm._v("Total")]),_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalExpense))+" ")])])]},proxy:true}])})],1)],1)],1)],1),_c('v-card-text',[_c('v-card',{staticClass:"px-5 py-2",attrs:{"color":"#E5E5E5","flat":""}},[_c('v-row',{staticClass:"headline font-weight-regular",style:({ color: _vm.profitColor })},[_c('v-col',[_vm._v("PROFIT")]),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalProfit)))])],1)],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }